<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-location">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24">
            <path d="M11.12.805c-1.048.12-2.177.525-3.14 1.125-.963.601-1.921 1.585-2.54 2.61-.813 1.347-1.265 3.114-1.185 4.64.144 2.749 1.84 6.47 4.978 10.92 1.032 1.465 2.678 3.566 2.766 3.532.096-.037 1.389-1.659 2.152-2.698 3.174-4.325 5.011-7.933 5.495-10.794.115-.682.125-1.784.021-2.453-.434-2.793-2.071-5.061-4.478-6.206a7.22 7.22 0 0 0-2.369-.68 10.054 10.054 0 0 0-1.7.004m1.74 1.52c1.576.207 3.096 1.148 4.105 2.543.492.679.967 1.76 1.13 2.572.164.813.181 1.819.044 2.578-.481 2.668-2.394 6.283-5.573 10.531-.3.401-.558.729-.574.73-.015.001-.286-.346-.602-.769-3.122-4.184-5.045-7.84-5.535-10.525-.086-.471-.113-1.331-.058-1.845.241-2.266 1.58-4.265 3.505-5.231 1.13-.567 2.264-.753 3.558-.584m-1.5 3.497c-.769.164-1.597.865-1.898 1.606A3.45 3.45 0 0 0 9.24 8.5c0 .52.197 1.137.497 1.557.548.769 1.341 1.183 2.263 1.183.922 0 1.715-.414 2.263-1.183.3-.42.497-1.037.497-1.557a3.45 3.45 0 0 0-.222-1.072c-.305-.751-1.133-1.445-1.918-1.608a3.627 3.627 0 0 0-1.26.002m1.048 1.502c.317.081.698.488.796.85.064.237.025.652-.08.858a1.35 1.35 0 0 1-.597.596 1.242 1.242 0 0 1-1.422-.267c-.261-.29-.324-.457-.324-.861 0-.297.015-.39.09-.529.317-.596.853-.822 1.537-.647"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-location",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
