<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-skip-backward">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 31.68 17.9">
            <path d="M15.22,17.9,4.83,9.73V8.16L15.22,0l1.62.79V17.11ZM7.07,9l7.77,6.1V2.84Z"/>
            <path d="M30.06,17.9,19.67,9.73V8.16L30.06,0l1.62.79V17.11ZM21.91,9l7.77,6.1V2.84Z"/>
            <rect y="2.27" width="2" height="13.35"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-skip-backward",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
