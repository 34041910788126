<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-punch">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24">
            <defs>
                <path id="A" d="M0 0h26v26H0z"/>
            </defs>
            <g fill-rule="evenodd">
                <mask id="B" fill="#fff">
                    <use xlink:href="#A"/>
                </mask>
                <path d="M13.014 26C5.817 26 0 20.177 0 13 0 5.796 5.817 0 13.014 0A12.98 12.98 0 0 1 26 13a13 13 0 0 1-12.986 13"
                      fill="#fff" mask="url(#B)"/>
                <path d="M22.5 15.287c.82.428.476 1.417.08 1.63l-3.517 1.95.423 3.42c.08.56-.317.775-.556.695l-3.226-1.15c-.132-.027-.185-.214-.132-.374s.185-.24.317-.187l2.46.294-.45-2.753c-.053-.294.16-.695.45-.882l2.327-1.577-3.412-2.592c-.21-.214-.185-.508-.053-.695l2.54-4.01-4.602.054c-.265 0-.476-.24-.53-.56l-.502-3.18-2.433 1.71c-.423.294-.714.347-1.137.107L8.51 5.505l-.37 2.7c-.053.374-.714.534-.714 0l-.026-4.01c0-.535.582-.748.846-.508L11.1 6.28l3.36-3.1c.37-.347 1.005-.16 1.032.294l.21 4.4 4.92-.722c1.428-.214 1.72 1.122 1.164 1.87 0 0-1.613 2.432-3.226 4.223l3.94 2.03m-8.33 1.844c.873-.668 1.93-1.336 2.724-.214.793 1.096-.238 2.085-1.11 2.78-1.402 1.176-2.592.722-2.936.267-.476-.64-.29-1.63 1.322-2.833m-1.825-4.917c1.2-.935 2.407-.935 3.174.16 1.032 1.47-.37 2.86-1.508 3.742-2.274 1.764-3.385 1.47-4.02.668-.132-.16-.238-.454-.265-.588.952.267 1.85.267 2.54.053.582-.187 1.005-.56 1.217-1.122.317-.935-.265-2.03-1.137-2.913M8.27 9.674c1.084-.855 2.698-1.79 3.782-.508.82.962.45 1.924-.29 2.646-.37-.347-.873-.802-1.48-1.23-.476-.32-1.455-.802-2-.91m3.28 10.957c.423.588.978.91 1.534.855-1.137 1.604-3.623 1.283-4.602.534-1.11-.802-1.587-2.673-.98-3.66-1.084.374-2.988.294-3.86-.882-.714-1.07-1.058-3.5.185-5.425 1.534-2.325 3.94-2.058 5.554-.935 2.433 1.63 3.465 2.833 3.174 3.715-.106.294-.344.508-.688.615-1.137.347-4.073-.802-5.2-2.486-.238-.347-1.322.535-.53 1.47a5.03 5.03 0 0 0 2.565 1.604c-.026.4.132 1.096.423 1.497.66.882 1.798 1.122 2.644.802-.37.695-.635 1.71-.21 2.298"
                      fill="#000"/>
            </g>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-punch",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
