import Vue from 'vue';

export default Vue.observable({
    name: '',
    logo: '/img/logo.svg',
    form_id: 0,
    video_id: 0,
    pp_page_id: 0,
    popup_id: 0,
    project_id: 0,
    projects: [],
    preview: {},
    labels: {
        home: {},
        trailer: {}
    },
    menu: [],
    overview: {},
    contact: {},
    info: {},
    form_field_errMessage: '',
    video: {},
    trailers: [],
    form: {},
    project: {},
    pp_page_content: false,
    popup_page_content: false,
    nf_page: {},
    mainReady: false,
    mute: true,
    current: {video: null},
    pages: [],
    posts: [],
    posts_categories: [],
    widget: null,
    widgets: [],
    fetching: {}
});
