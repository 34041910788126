<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-call">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24">
            <path d="M6.927 3c-.362 0-.718.13-1.015.367l-.043.022-.022.022-2.16 2.226.022.022a2.17 2.17 0 0 0-.583 2.334c.003.005-.003.016 0 .022.586 1.678 2.083 4.917 5.01 7.845 2.936 2.94 6.215 4.38 7.837 5.014h.022c.84.28 1.75.08 2.396-.475l2.18-2.183a1.55 1.55 0 0 0 0-2.14l-2.807-2.8-.022-.043c-.572-.573-1.587-.573-2.16 0l-1.382 1.383c-.5-.24-1.69-.856-2.828-1.945-1.13-1.08-1.708-2.323-1.92-2.8l1.382-1.383c.58-.58.59-1.548-.022-2.118l.022-.022-.065-.065L7.985 3.39l-.043-.022C7.645 3.13 7.29 3 6.927 3zm0 1.383c.05 0 .103.024.15.065L9.84 7.28l.065.065c-.005-.005.04.068-.043.15l-1.727 1.73-.324.303.15.432s.793 2.126 2.46 3.717l.15.13a13.92 13.92 0 0 0 3.497 2.269l.432.195 2.05-2.053c.12-.12.097-.12.216 0l2.828 2.83c.12.12.12.076 0 .195l-2.116 2.118c-.318.273-.656.33-1.058.195-1.565-.616-4.606-1.953-7.318-4.668-2.734-2.737-4.16-5.84-4.685-7.348-.105-.28-.03-.697.216-.908l.043-.043 2.094-2.14c.05-.04.1-.065.15-.065z"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-call",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
