<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-expand">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 32 23">
            <polygon points="32 8 30 8 30 2 24 2 24 0 32 0 32 8"/>
            <polygon points="32 23 24 23 24 21 30 21 30 15 32 15 32 23"/>
            <polygon points="8 23 0 23 0 15 2 15 2 21 8 21 8 23"/>
            <polygon points="2 8 0 8 0 0 8 0 8 2 2 2 2 8"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-expand",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
