<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-mail">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24">
            <path d="M21.5 5v14h-19V5h19zm-1.6 5.042l-7.892 4.055L4.1 10.152V17.4h15.8v-7.358zM19.9 6.6H4.1v1.764l7.898 3.94 7.902-4.06V6.6z" />
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-mail",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
