import axios from 'axios'
import OAuth from 'oauth-1.0a'
import CryptoJS from 'crypto-js'


const URL = process.env.VUE_APP_API_URL

// Set up essential values
const secretData = {
    gfKey: process.env.VUE_APP_GF_KEY,
    gfSecret: process.env.VUE_APP_GF_SECRET
}

const auth = OAuth({
    consumer: {
        key: secretData.gfKey,
        secret: secretData.gfSecret
    },
    signature_method: 'HMAC-SHA1',
    hash_function(base_string, key) {
        return CryptoJS.HmacSHA1(base_string, key).toString(CryptoJS.enc.Base64)
    },
})

const actions = {

    async fetchEntry(url, params = {}) {
        let post = null
        try {
            let res = await axios.get(url, {params})
            // console.log(res)
            if (res.data) {
                post = res.data
            }
        } catch (error) {
            console.log(error)
            return Promise.reject(error.response)
        }

        return post;
    },

    async fetchEntries(posts, slug) {
        const recordLimit = 100
        let page = 1
        let fetchMore = true
        let url = URL + "/wp-json/wp/v2/" + slug + "/?"
        let params = {
            status: 'publish',
            per_page: recordLimit,
            page
        }

        while (fetchMore) {
            try {
                // var url_params = () => {
                //     var $url = [];
                //     $url.push('status=publish');
                //     $url.push('per_page=' + recordLimit);
                //     $url.push('page=' + page);
                //     return $url.join('&')
                // };

                let res = await axios.get(url, {params})
                if (res.data && res.data.length) {
                    posts.push(...res.data)
                    if (res.data.length < recordLimit) fetchMore = false
                } else {
                    fetchMore = false
                }
                page += 1
            } catch (error) {
                fetchMore = false
            }
        }

        return posts;
    },

    async getVideo(id) {
        let url = URL + "/wp-json/wp/v2/video/" + id

        return await this.fetchEntry(url)
    },

    async getForm(id) {
        let post = null
        let url = URL + "/wp-json/gf/v2/forms/" + id;
        const authOptions = {
            method: 'GET',
            url
        };

        try {
            let res = await axios.get(url, {params: auth.authorize(authOptions)})
            if (res.data) {
                // console.log('data form:', res.data)
                post = res.data
            }
        } catch (error) {
            console.log(error)
        }

        // console.log('post: ', post)
        return post;
    },

    async submitForm(id, data) {
        let url = URL + "/wp-json/gf/v2/forms/" + id + "/submissions"
        let post = {}

        const options = {
            method: 'POST',
            data,
            url,
            validateStatus: function (status) {
                return status = 400;
            }
        };

        try {
            let res = await axios(options)
            if (res.status) {
                console.log('submit ', res);
                post.data = res.data || {}
                post.status = res.status
            }

        } catch (error) {
            console.log(error)
            post.error = error
        }

        return post
    },

    async fetchPage(id, slug = false) {
        let url
        if (slug) {
            url = URL + "/wp-json/wp/v2/pages/?slug=" + id
        } else {
            url = URL + "/wp-json/wp/v2/pages/" + id
        }

        return await this.fetchEntry(url)
    },

    async fetchPost(id, slug = false) {
        let url = URL + "/wp-json/wp/v2/posts/" + id
        if (slug) {
            url = URL + "/wp-json/wp/v2/posts/?slug=" + id
        }

        return await this.fetchEntry(url)
    },

    async fetchPopup(id) {
        let url = URL + "/wp-json/wp/v2/popup/" + id

        return await this.fetchEntry(url)
    },

    async fetchProject(id) {
        let url = URL + "/wp-json/wp/v2/project/" + id

        return await this.fetchEntry(url)
    },

    async fetchTrailer(id) {
        let url = URL + "/wp-json/wp/v2/trailer/" + id

        return await this.fetchEntry(url)
    },

    async fetchWidget(id) {
        let url = URL + "/wp-json/wp/v2/widget/" + id

        return await this.fetchEntry(url)
    },

    async fetchSite(path = 'site') {
        let url = URL + "/wp-json/theme/v1/" + path

        return await this.fetchEntry(url)
    },

    async fetchProjects() {
        let url = URL + "/wp-json/wp/v2/project/"
        return await this.fetchEntry(url)
    },

    async getMedia(id) {
        let url = URL + "/wp-json/wp/v2/media/" + id

        return await this.fetchEntry(url)
    },

    async preloadFile(url) {
        return await axios({
            url,
            method: 'HEAD',
            responseType: 'blob', // Important
        })
    }

}


export default actions
