<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-mute">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 28.23" >
            <path d="M14,28.83,5.39,20.21H0V8.61H5.39L14,0ZM2,18.21H6.21L12,24V4.83L6.21,10.61H2Z"/>
            <rect x="18.5" y="8.96" width="2" height="9.9"
                  transform="translate(-4.13 17.86) rotate(-45)"/>
            <rect x="14.55" y="12.91" width="9.9" height="2"
                  transform="translate(-4.13 17.86) rotate(-45)"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-mute",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
