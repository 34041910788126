<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-message">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24">
            <path d="M2.3 12c0-2.59 1.01-5.027 2.84-6.86S9.41 2.3 12 2.3s5.027 1.01 6.86 2.84S21.7 9.41 21.7 12s-1.01 5.027-2.84 6.86S14.59 21.7 12 21.7a9.65 9.65 0 0 1-5.134-1.469L2.22 21.78l1.548-4.645A9.65 9.65 0 0 1 2.3 12zm4.706 6.524l.176.025.24.165A8.08 8.08 0 0 0 12 20.125c4.48 0 8.125-3.645 8.125-8.125S16.48 3.875 12 3.875 3.875 7.52 3.875 12a8.08 8.08 0 0 0 1.412 4.578l.164.24.025.176-.765 2.294 2.294-.765z"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-message",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
