// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueFilterDateFormat from 'vue-filter-date-format'
import Vue2touchEvents from 'vue2-touch-events'
import VueRangeSlider from 'vue-range-component'
import 'vue-range-component/dist/vue-range-slider.css'
import LottieAnimation from 'lottie-vuejs'
import VueCarousel from 'vue-carousel'
import device from 'vue-device-detector'
import ThemeIcons from './modules/icons'
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";

import PlayIcon from 'vue-ionicons/dist/md-play'
import PauseIcon from 'vue-ionicons/dist/md-pause'
import VolumeHighIcon from 'vue-ionicons/dist/md-volume-high'
import VolumeLowIcon from 'vue-ionicons/dist/md-volume-low'
import VolumeMuteIcon from 'vue-ionicons/dist/md-volume-mute'
import VolumeOffIcon from 'vue-ionicons/dist/md-volume-off'
import NextIcon from 'vue-ionicons/dist/md-skip-forward'
import PrevIcon from 'vue-ionicons/dist/md-skip-backward'
import ExpandIcon from 'vue-ionicons/dist/md-expand'
import FilingIcon from 'vue-ionicons/dist/md-filing'
import TextIcon from 'vue-ionicons/dist/md-text'
import UndoIcon from 'vue-ionicons/dist/md-undo'
import RedoIcon from 'vue-ionicons/dist/md-redo'
import ArrowBack from 'vue-ionicons/dist/md-arrow-round-back'
import ChevronLeft from 'vue-ionicons/dist/ios-arrow-back'
import ChevronRight from 'vue-ionicons/dist/ios-arrow-forward'
import LogoIcons from 'vue-ionicons/dist/ionicons-logo'
import IonIcon from 'vue-ionicons/dist/ionicons-md'
import VideocamIcon from 'vue-ionicons/dist/ios-videocam'
import HeartIcon from 'vue-ionicons/dist/md-heart'
import BrushIcon from 'vue-ionicons/dist/md-brush'
import LogoVimeo from 'vue-ionicons/dist/logo-vimeo'
import LogoFacebook from 'vue-ionicons/dist/logo-facebook'
import LogoLinedIn from 'vue-ionicons/dist/logo-linkedin'
import LogoTwitter from 'vue-ionicons/dist/logo-twitter'
import MenuIcon from 'vue-ionicons/dist/ios-menu'
import CheckmarkIcon from 'vue-ionicons/dist/md-checkmark'
import LinkPrivacy from "./components/LinkPrivacy";

import router from "./router";
import App from './App';

import 'video.js/dist/video-js.css'
import './style.scss'

Vue.config.productionTip = false

Vue.use(VueFilterDateFormat)
Vue.use(VueRangeSlider)

Vue.use(Vue2touchEvents)
Vue.use(VueCarousel)
Vue.use(device)
Vue.use(LottieAnimation)

// Vue.use(IonIcon)
Vue.filter('secondsToDateTime', function (seconds) {
    let date = new Date(1970, 0, 1)
    date.setSeconds(seconds)
    return date
})

Vue.use(IonIcon)
Vue.use(LogoIcons)
Vue.use(ThemeIcons)

Vue.component('play-icon', PlayIcon)
Vue.component('pause-icon', PauseIcon)
Vue.component('next-icon', NextIcon)
Vue.component('prev-icon', PrevIcon)
Vue.component('expand-icon', ExpandIcon)
Vue.component('filing-icon', FilingIcon)
Vue.component('text-icon', TextIcon)
Vue.component('undo-icon', UndoIcon)
Vue.component('redo-icon', RedoIcon)
Vue.component('volume-high-icon', VolumeHighIcon)
Vue.component('volume-low-icon', VolumeLowIcon)
Vue.component('volume-mute-icon', VolumeMuteIcon)
Vue.component('volume-off-icon', VolumeOffIcon)
Vue.component('arrow-back', ArrowBack)
Vue.component('chevron-left', ChevronLeft)
Vue.component('chevron-right', ChevronRight)
Vue.component('icon-brush', BrushIcon)
Vue.component('icon-heart', HeartIcon)
Vue.component('icon-videocam', VideocamIcon)
Vue.component('logo-vimeo-icon', LogoVimeo)
Vue.component('logo-facebook-icon', LogoFacebook)
Vue.component('logo-linkedIn-icon', LogoLinedIn)
Vue.component('logo-twitter-icon', LogoTwitter)
Vue.component('icon-menu', MenuIcon)
Vue.component('icon-checkmark', CheckmarkIcon)
Vue.component('link-privacy', LinkPrivacy)

const mountEl = document.querySelector('#app');
/* eslint-disable no-new */
export const EventBus = new Vue;

Vue.use(VueGtag, {
    config: {id: "UA-175724130-1"}
}, router);

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

new Vue({
    router,
    render: h => h(App, {props: {...mountEl.dataset}}),
}).$mount('#app');

