<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-close">
        <svg viewBox="0 0 27 27" :width="w" :height="h" class="ion__svg">
            <polygon points="13.5 11.1521739 24.6521739 0 27 2.34782609 15.8478261 13.5 27 24.6521739 24.6521739 27 13.5 15.8478261 2.34782609 27 0 24.6521739 11.1521739 13.5 0 2.34782609 2.34782609 0"></polygon>
        </svg>
        <!--<svg viewBox="0 0 512 512" :width="w" :height="h" class="ion__svg">-->
            <!--<path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"/>-->
        <!--</svg>-->
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-close",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
