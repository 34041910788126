<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-skip-forward">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 31.68 17.9">
            <path d="M16.46,17.9l-1.62-.79V.79L16.46,0,26.84,8.16V9.73Zm.38-15.06V15.05L24.61,9Z"/>
            <path d="M1.62,17.9,0,17.11V.79L1.62,0,12,8.16V9.73ZM2,2.84V15.05L9.77,9Z"/>
            <rect x="29.68" y="2.27" width="2" height="13.35"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-skip-forward",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
