<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-arrow-back">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 48 38">
            <path d="M18.798 1.116l2.38 2.405L7.574 17.273H48v3.455H7.574L21.176 34.48l-2.38 2.405L1.11 19 18.798 1.116z" />
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-arrow-back",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
