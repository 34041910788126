<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-pause">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24">
            <path d="M6.8 3.5h2v17h-2zm8.5 0h2v17h-2z" />
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-pause",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
