export default {
    HOME: 'Home',
    WATCH: 'Watch',
    WATCH_MAIN: "WatchMain",
    OVERVIEW: "Overview",
    INFO: "OverviewInfo",
    EPISODES: "Episodes",
    TRAILERS: "Trailers",
    DETAILS: "Details",
    PRIVACY: "Privacy",
    CONTACT: "Contact",
    CONTACT_US: "ContactUs",
    PAGE: "Page",
    POST: "Post",
    ABOUT: "About",
    NOT_FOUND: 'NotFound'
}