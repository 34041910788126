<template>
    <div class="content" :class="{'is-mobile': $device.mobile}">
        <keep-alive :max="3">
            <router-view :key="$route.params.id>0?$route.params.id:'0'" name="player"/>
        </keep-alive>
        <transition name="fade" mode="out-in" @before-enter="mainEnter=true" @after-enter="mainEntered=true"
                    @before-leave="mainEnter=false" @after-leave="mainEntered=false">
            <keep-alive :max="1">
                <router-view/>
            </keep-alive>
        </transition>
        <transition name="opacity" mode="out-in">
            <keep-alive>
                <router-view name="bottomBar"/>
            </keep-alive>
        </transition>
        <transition name="fade" mode="in-out">
            <router-view key="overlay" name="back" :transitionEntered="overlayEntered"/>
        </transition>
        <transition name="fade" mode="out-in" @after-enter="overlayEntered=true"
                    @leave="overlayEntered=false">
            <router-view key="info" name="overlay" :transitionEntered="overlayEntered"/>
        </transition>
        <transition name="opacity" mode="out-in" @after-enter="overlayEntered=true"
                    @leave="overlayEntered=false">
            <keep-alive :max="3">
                <router-view :key="$route.params.page||'page'" name="page" :transitionEntered="overlayEntered"/>
            </keep-alive>
        </transition>
        <transition name="opacity" mode="out-in" @after-enter="overlayEntered=true"
                    @leave="overlayEntered=false">
            <!--            <keep-alive :max="3">-->
            <router-view key="post" name="post" :transitionEntered="overlayEntered"/>
            <!--            </keep-alive>-->
        </transition>

        <transition name="fade" mode="out-in">
            <router-view key="menu" name="menu" :transitionEntered="overlayEntered"/>
        </transition>

        <transition name="opacity" mode="out-in">
            <keep-alive>
                <router-view name="navbar"/>
            </keep-alive>
        </transition>

        <transition name="opacity" mode="out-in">
            <router-view name="logo"></router-view>
        </transition>

        <borish-popup v-if="store.popup_page_content && Object.keys(store.popup_page_content).length"
                      @click="[onPopupClose(store.popup_page_content.id),$router.push({name:'ContactUs'})]"
                      @close="onPopupClose(store.popup_page_content.id)" :delay="bannerDelay">
            <div v-html="store.popup_page_content.content.rendered"></div>
        </borish-popup>

    </div>
</template>

<script>
import actions from './modules/fetchAxios';
import store from "./store";
import BorishPopup from './components/borishPopup'


export default {
    name: 'App',
    components: {BorishPopup/*:import(/* webpackChunkName: "BorishPopup" / "./components/borishPopup")*/},
    data() {
        return {
            mainEnter:true,
            mainEntered: false,
            overlayEntered: false,
            store: store,
            bannerDelay: 2000,
            mainVidReady: false,
            autoplayDelay: 1000,
        }
    },
    mounted() {
        this.loadInitial();
        this.setWindowHeight();
    },
    created() {
        window.addEventListener('resize', this.setWindowHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setWindowHeight);
    },
    computed: {
        duration() {
            if (!this.mainEntered && this.$route.params.trailer && this.$route.params.trailer > 0) {
                return 500
            }
            return 300
        }
    },
    watch: {
        'store.popup_id': {
            handler: 'maybeShowPopup',
            immediate: true
        },
        'store.video_id': {
            handler: 'loadVideo',
            immediate: true
        },

        // 'store.projects': {
        //     handler: function () {
        //         this.setProject()
        //         this.preloadProjectImages()
        //     },
        //     immediate: true
        // },

        // 'store.project_id': {
        //     handler: 'loadProject',
        //     immediate: true
        // },

        // 'store.video': {
        //     handler: 'prepareVideo',
        //     immediate: true
        // },

        // 'store.mainReady': {
        //     handler: 'maybePushHome',
        //     immediate: true
        // },
        // mainVidReady: {
        //     handler: 'maybePushOverview',
        // }
    },
    methods: {
        async loadInitial() {
            await actions.fetchSite().then((res) => {
                for (let key in res) {
                    if (store.hasOwnProperty(key)) {
                        store[key] = res[key];
                    }
                }
            })
        },

        async maybeShowPopup() {
            if (!this.store.popup_id)
                return

            if (this.$route.name == "ContactUs")
                return

            let shown = sessionStorage.getItem('bp_orderpopup')
            if (!shown || shown != this.store.popup_id) {
                store.popup_page_content = await actions.fetchPopup(this.store.popup_id)
            }
        },

        async loadVideo() {
            if (!this.store.video_id)
                return

            store.video = await actions.getVideo(this.store.video_id)
        },

        async loadProject() {
            if (!this.store.project_id)
                return

            store.project = await actions.fetchProject(this.store.project_id)
        },

        async loadProjects() {
            store.projects = await actions.fetchProjects()
        },

        async prepareVideo() {
            if (this.mainVidReady) {
                return;
            }

            if (!this.store.video || !this.store.video.video_file || !this.store.video.video_file.url)
                return;

            await actions.preloadFile(this.store.video.video_file.url).then((res) => {
                console.log(res)
                if (res.status === 200) {
                    let videoBlob = res.data;
                    let vid = URL.createObjectURL(res.data); // IE10+
                    console.log(vid)
                    // Video is now downloaded
                    // and we can set it as source on the video element
                    // store.video.video_file.url = vid;

                }
                this.mainVidReady = true
            })
        },

        setProject() {
            if (!this.store.projects.length)
                return;

            if (Object.keys(this.store.project).length)
                return;

            store.project = this.store.projects[Math.floor(Math.random() * this.store.projects.length)];
        },

        preloadProjectImages() {
            if (!this.store.projects.length)
                return;

            this.store.projects.forEach(project => {
                if (project.gallery) {
                    project.gallery.forEach(img => {
                        if (!this.$device.mobile) {
                            new Image().src = img.desktop || img.mobile
                        } else {
                            new Image().src = img.mobile || img.desktop
                        }
                    })
                }
            })
        },

        maybePushHome() {
            console.log('Push Router')
            console.log(store.mainReady)
            if (this.$route.name !== "Home")
                return

            setTimeout(() => {
                if (this.$route.name !== "Home")
                    return
                // this.$router.push({name: "Home", params: {run: true}})
                this.$router.replace({params: {...this.$route.params, run: true}})
                // this.$router.update({ params: {run: true}})
                // this.$route.params = {...this.$route.params, run: true }
            }, this.autoplayDelay)
        },

        maybePushOverview() {
            console.log('Push Router')
            console.log(store.mainReady)
            if (this.$route.name !== "Home")
                return

            setTimeout(() => {
                if (this.$route.name !== "Home")
                    return
                this.$router.push({name: "OverviewInfo", params: {run: true}})
            }, this.autoplayDelay)
        },

        onPopupClose(id) {
            sessionStorage.setItem('bp_orderpopup', JSON.stringify(id));
        },

        setWindowHeight() {
            let height = document.documentElement.clientHeight;
            document.documentElement.style.setProperty('--windowHeight', `${height}px`);
        },

        afterEnter() {
            this.$nextTick(() => {
                this.overlayEntered = true
            })
        }
    }
}
</script>

<style lang="scss">

</style>
