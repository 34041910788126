<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-spider">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24">
            <defs>
                <path id="A" d="M0 0h26v26H0z"/>
            </defs>
            <g fill-rule="evenodd">
                <mask id="B" fill="#fff">
                    <use xlink:href="#A"/>
                </mask>
                <path d="M13.014 26C5.817 26 0 20.177 0 13 0 5.796 5.817 0 13.014 0A12.98 12.98 0 0 1 26 13a13 13 0 0 1-12.986 13"
                      fill="#fff" mask="url(#B)"/>
                <path d="M20.97 10.858c-.345.45-2.253 1.775-2.784 1.88-.37.053-4.056.61-4.135.318-.08-.265 3.287-1.086 4.082-1.536 1.034-.583 1.538-3.973 1.38-4.927-.186-1.218-2.57-5.14-4.268-4.53-.424.16 2.492 4.21 2.624 4.953.08.424-.053 3.31-.477 3.602-.185.106-3.605 1.854-3.738 1.67-.133-.265 2.253-1.96 2.253-2.914 0-1.934-2.068-4.768-2.916-4.768-.822 0-2.943 2.834-2.943 4.768 0 .954 2.412 2.65 2.28 2.914-.133.185-3.552-1.563-3.712-1.67-.45-.29-.583-3.18-.504-3.602.133-.742 3.075-4.794 2.625-4.953-1.697-.61-4.083 3.31-4.268 4.53-.16.954.345 4.344 1.378 4.927.795.45 4.162 1.27 4.083 1.536-.08.29-3.764-.265-4.11-.318-.557-.106-2.465-1.43-2.783-1.88-.345-.45-.9-4.927-1.246-4.556-1.352 1.5-.716 4.874.026 5.67.504.53 3.05 1.616 3.658 1.722.822.16 4.268 0 4.268.265.026.29-2.97.662-3.552.874-.557.185-2.306 1.113-2.73 1.748-.557.82-1.034 4.185.318 4.212.6.026.77-3.126 1.008-3.576.106-.265 1.67-1.35 2.04-1.536s3.1-1.086 3.128-.9c.053.238-2.094 1.404-2.678 2.12-.45.583-.742 2.4-.742 3.02.026.61 1.51 3.55 2.545 3.02.424-.212-1.008-2.94-.98-3.23 0-.477-.053-1.59.106-2.04.186-.53 1.564-2.146 1.83-2.013.106.053-.16 1.59.292 1.59.37 0 .265-1.616.663-1.616s.292 1.616.663 1.616c.477 0 .186-1.536.292-1.59.265-.132 1.617 1.484 1.803 2.013.16.45.106 1.563.133 2.04 0 .29-1.378 3.02-.98 3.23 1.007.53 2.545-2.4 2.545-3.02.026-.61-.292-2.437-.77-3.02-.583-.715-2.704-1.88-2.65-2.12.026-.185 2.757.715 3.128.9s1.935 1.27 2.068 1.536c.212.45.398 3.602.98 3.576 1.326-.027.848-3.39.318-4.212-.424-.636-2.147-1.563-2.73-1.748-.557-.212-3.58-.583-3.552-.874 0-.265 3.473-.106 4.268-.265.6-.106 3.128-1.192 3.632-1.722.77-.795 1.458-4.185.053-5.67-.345-.37-.928 4.106-1.22 4.556"/>
            </g>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-spider",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
