<template>
    <router-link :to="{name:C.PRIVACY}"><slot/></router-link>
</template>

<script>
import C from "../router/constants"

export default {
    name: "LinkPrivacy",
    computed: {
        C() {
            return C;
        }
    }
}
</script>

<style scoped>

</style>