<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-filling">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 32 25">
            <path d="M28,25H0V4H28ZM2,23H26V6H2Z"/>
            <polygon points="32 21 28.13 21 28.13 19 30 19 30 2 6 2 6 3.98 4 3.98 4 0 32 0 32 21"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-filling",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
