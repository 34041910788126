<template>
    <transition name="popup-slide">
        <div v-if="visible" class="popup">
            <div class="popup__top">
                <a class="popup__close" @click="close">
                    <md-close-icon></md-close-icon>
                </a>
            </div>
            <div class="popup__inner" @click.prevent="proceed">
                <slot></slot>
            </div>
        </div>
    </transition>

</template>

<script>
    export default {
        props: ['delay'],
        name: "borishPopup",
        data() {
            return {
                visible: false
            }
        },
        mounted() {
            setTimeout(() => {
                this.visible = true
            }, this.delay)
        },

        methods: {
            close() {
                this.visible = false
                this.$emit('close')
            },
            proceed() {
                this.visible = false
                this.$emit('click')
            }
        }
    }
</script>

<style scoped>

</style>
