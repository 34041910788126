<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-play">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24" >
            <path  d="M6.1,20.8L4.5,20V2l1.6-0.8l11,9v1.5L6.1,20.8z M6.5,4.1v13.8l8.4-6.9L6.5,4.1z"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-play",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
