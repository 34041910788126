import IconArrowBack from '../icons/iconArrowBack'
import IconCall from '../icons/iconCall'
import IconClose from '../icons/iconClose'
import IconExpand from '../icons/iconExpand'
import IconFilling from '../icons/iconFilling'
import IconHouse from '../icons/iconHouse'
import IconInfo from '../icons/iconInfo'
import IconLocation from '../icons/iconLocation'
import IconMail from '../icons/iconMail'
import IconMessage from '../icons/iconMessage'
import IconMute from '../icons/iconMute'
import IconPause from '../icons/iconPause'
import IconPlay from '../icons/iconPlay'
import IconPunch from '../icons/iconPunch'
import IconSkipForward from '../icons/iconSkipForward'
import IconSkipBackward from '../icons/iconSkipBackward'
import IconSpider from '../icons/iconSpider'
import IconSpinner from '../icons/iconSpinner'
import IconText from '../icons/iconText'
import IconVolumeDown from '../icons/iconVoluemDown'
import IconVolumeMute from '../icons/iconVoluemMute'
import IconVolumeUp from '../icons/iconVoluemUp'

export default {
    install: Vue => {
        Vue.component('icon-arrow-back', IconArrowBack)
        Vue.component('icon-call', IconCall)
        Vue.component('icon-close', IconClose)
        Vue.component('icon-expand', IconExpand)
        Vue.component('icon-filling', IconFilling)
        Vue.component('icon-house', IconHouse)
        Vue.component('icon-info', IconInfo)
        Vue.component('icon-location', IconLocation)
        Vue.component('icon-mail', IconMail)
        Vue.component('icon-message', IconMessage)
        Vue.component('icon-mute', IconMute)
        Vue.component('icon-pause', IconPause)
        Vue.component('icon-play', IconPlay)
        Vue.component('icon-punch', IconPunch)
        Vue.component('icon-skip-forward', IconSkipForward)
        Vue.component('icon-skip-backward', IconSkipBackward)
        Vue.component('icon-spider', IconSpider)
        Vue.component('icon-spinner', IconSpinner)
        Vue.component('icon-text', IconText)
        Vue.component('icon-volume-down', IconVolumeDown)
        Vue.component('icon-volume-mute', IconVolumeMute)
        Vue.component('icon-volume-up', IconVolumeUp)
    }
}
