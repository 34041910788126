<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-house">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 24">
            <path d="M12 2.5l-.524.5L2 12.477l1.048 1.048.934-.934v8.428h6.56v-7.29h2.916v7.29h6.56V12.59l.934.934L22 12.477 12.524 3 12 2.5zm0 2.073l6.56 6.56v8.428h-3.645v-7.29h-5.83v7.29H5.44v-8.428L12 4.573z"
                 />
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-house",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
