<template>
    <div
            class="ion"
            :class="ionClass"
            :title="iconTitle"
            name="icon-volume-mute">
        <svg :width="w" :height="h" class="ion__svg" viewBox="0 0 24 28.83">
            <path d="M14,28.83,5.39,20.21H0V8.61H5.39L14,0ZM2,18.21H6.21L12,24V4.83L6.21,10.61H2Z"/>
        </svg>
    </div>
</template>

<script>
    import IoniconsMixin from './mixin'

    export default {
        name: "icon-volume-mute",
        mixins: [
            IoniconsMixin
        ],
        data() {
            let iconTitle = this.title ? this.title : ""
            return {
                iconTitle: iconTitle
            }
        }
    }
</script>
