import Vue from "vue";
import VueRouter from "vue-router";
import {find as _find} from "lodash";
import c from "./constants"
import store from "../store";
import fetchAxios from "../modules/fetchAxios";

Vue.use(VueRouter);

const contactRouts = ['address', 'email', 'phone'];

const rememberVideo = (to, from, next) => {
    // console.log(from)
    if (from.params.id !== undefined && !to.params.id) {
        to.params.id = from.params.id
        console.log(from.params.id)
        console.log(to.params.id)
    }
    if (typeof from.params.id === undefined && !to.params.id) {
        to.params.id = 0
    }
    // console.log(to)
    next()
}

const beforeOverviewEnter = (to, from, next) => {
    to.params.pause = true
    if (!to.params.menuSlug) {
        to.params.menuSlug = to.path.replace(/^\//, '')
    }
//     if (to.name !== c.INFO){
//         to.params.trailer = 0
//     }
// console.log(to.name)
    next()
}


const beforeContactEnter = (to, from, next) => {
    to.params.pause = true
    if (!to.params.action) {
        to.params.action = to.path.replace(/^\//, '') || 'call'
    }

    if (!contactRouts.includes(to.params.action)) {
        next({name: c.PAGE, params: {page: to.params.action}});
    }

    next()
}

const beforePageEnter = (to, from, next) => {
    to.params.pause = true
    if (to.params.page) {
        fetchAxios.fetchPage(to.params.page, true)
            .then(res => {
                if (res.length) {
                    store.page = res[0]
                    next();
                } else {
                    next({name: 'NotFound'});
                }
            })
    } else {
        next({name: 'NotFound'});
    }
}

const beforePostEnter = (to, from, next) => {
    to.params.pause = true
    to.params.key = 'post'
    to.params.from = from
    to.params.menuSlug = 'about'
    if (to.params.page) {
        if (store.posts && store.posts.length) {
            store.post = _find(store.posts, {slug: to.params.page});
            console.log("SET post")
            console.log(store.post.slug)
            next();
        } else {
            fetchAxios.fetchPost(to.params.page, true)
                .then(res => {
                    if (res.length) {
                        store.post = res[0]
                        next();
                    } else {
                        if (from)
                            next({name: 'NotFound'});
                    }
                })
        }
    } else {
        if (from)
            next({name: 'NotFound'});
    }
}

const pauseVideo = (to, from, next) => {
    to.params.pause = true

    next()
}

const routes = [
    {
        path: "/",
        name: c.HOME,
        components: {
            default: () => import('../views/Home'),
            bottomBar: () => import('../views/TrailersModal.vue'),
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            logo: () => import(/* webpackChunkName: "Logo"*/ '../views/Logo'),
            navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar')
        },
        props: {
            default: true,
            player: {isHome: true},
            navbar: true,
            logo: {isHome: true}
        },
        beforeEnter: (to, from, next) => {
            to.params.id = 0
            to.params.pause = false
            next()
        }
    },
    {
        path: '/watch/:id',
        name: c.WATCH,
        props: {player: true, menu: true},
        components: {
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar'),
            logo: () => import(/* webpackChunkName: "Navbar"*/ '../views/NavBacklink.vue')
        }
    },
    {
        path: '/watch',
        name: c.WATCH_MAIN,
        props: {player: true, menu: true},
        components: {
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar'),
            logo: () => import(/* webpackChunkName: "Navbar"*/ '../views/NavBacklink.vue')
        },
        beforeEnter: (to, from, next) => {
            to.params.id = -1
            next()
        }
    },
//ABOUT is duplicated ??
    {
        path: '/about',
        name: c.ABOUT,
        props: {
            overlay: true,
            default: true,
            post: true,
            player: true,
            menu: true,
            navbar: {
                hasClose: true
            }
        },
        components: {
            logo: () => import(/* webpackChunkName: "Logo"*/ '../views/Logo'),
            navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar'),
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            back: () => import(/* webpackChunkName: "Overlay" */ '../views/PageOverlay'),
            overlay: () => import(/* webpackChunkName: "About" */ '../views/About'),
            menu: () => import(/* webpackChunkName: "NavbarOverlay" */ '../views/NavbarOverview')
        },
        beforeEnter: beforeOverviewEnter,
    },
    {
        path: '/',
        name: c.OVERVIEW,
        props: {
            overlay: true,
            default: true,
            post: true,
            player: true,
            menu: true,
            navbar: {
                hasButtons: true,
                hasClose: true
            }
        },
        components: {
            logo: () => import(/* webpackChunkName: "Logo"*/ '../views/Logo'),
            navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar'),
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            // back: () => import(/* webpackChunkName: "Overlay" */ '../views/PageOverlay'),
            overlay: () => import(/* webpackChunkName: "About" */ '../views/Overview.vue'),
            menu: () => import(/* webpackChunkName: "NavbarOverlay" */ '../views/NavbarOverview')
        },
        beforeEnter: beforeOverviewEnter,
        children: [
            {
                path: 'overview',
                name: c.INFO,
                props: true,
                component: () => import(/* webpackChunkName: "OverviewInfo" */ '../views/OverviewInfo'),
                beforeEnter: beforeOverviewEnter,
            }
        ]
    },

    {
        path: '/',
        name: c.OVERVIEW,
        props: {
            overlay: true,
            default: true,
            player: true,
            menu: true,
            navbar: {
                hasClose: true
            }
        },
        components: {
            logo: () => import(/* webpackChunkName: "Logo"*/ '../views/Logo'),
            navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar'),
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            overlay: () => import(/* webpackChunkName: "Overlay" */ '../views/Overview'),
            // back: () => import(/* webpackChunkName: "Overlay" */ '../views/PageOverlay'),
            menu: () => import(/* webpackChunkName: "NavbarOverlay" */ '../views/NavbarOverview'),
        },
        beforeEnter: beforeOverviewEnter,
        children: [
            // {
            //     path: 'overview',
            //     name: c.INFO,
            //     props: true,
            //     component: () => import(/* webpackChunkName: "OverviewInfo" */ '../views/OverviewInfo'),
            //     beforeEnter: beforeOverviewEnter,
            // },
            {
                path: 'episodes',
                name: c.EPISODES,
                props: true,
                component: () => import(/* webpackChunkName: "Episodes" */ '../views/Episodes'),
                beforeEnter: beforeOverviewEnter,
            },
            //disable path
            // {
            //     path: 'trailers',
            //     name: c.TRAILERS,
            //     props: true,
            //     component: () => import(/* webpackChunkName: "Trailers" */ '../views/Trailers'),
            //     beforeEnter: beforeOverviewEnter,
            // },
            {
                path: 'contact',
                name: c.DETAILS,
                props: true,
                component: () => import(/* webpackChunkName: "Details" */ '../views/Details'),
                beforeEnter: beforeOverviewEnter,
            }, {
                path: 'privacy',
                name: c.PRIVACY,
                props: true,
                component: () => import(/* webpackChunkName: "Privacy" */ '../views/Privacy'),
                beforeEnter: beforeOverviewEnter,
            },
            // {
            //     path: 'about',
            //     name: c.ABOUT,
            //     props: true,
            //     // component: () => import(/* webpackChunkName: "About" */ '../views/About'),
            //     components: {
            //         default: () => import(/* webpackChunkName: "About" */ '../views/About'),
            //         overlay: () => import(/* webpackChunkName: "Contact" */ '../views/Contact')
            //         // back: ()=>false,
            //         // logo:()=>false
            //         // overlay: false
            //     },
            //     beforeEnter: beforeOverviewEnter,
            // }
        ]
    },
    {
        path: '/',
        name: c.CONTACT,
        props: {
            overlay: true,
            default: true,
            player: route => ({...route.params, ...{pause: true}}),
            navbar: {hasClose: true}
        },
        components: {
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar'),
            overlay: () => import(/* webpackChunkName: "Contact" */ '../views/Contact')
        },
        beforeEnter: beforeContactEnter,
        children: [
            {
                path: ':action',
                alias: ['/address', '/call', '/email'],
                name: 'ContactDetails',
                props: true,
                component: () => import(/* webpackChunkName: "OverviewInfo" */ '../views/ContactDetails'),
            }
        ]
    },
    {
        path: '/contact-us',
        name: c.CONTACT_US,
        props: {
            overlay: true,
            default: true,
            player: route => ({...route.params, ...{pause: true}}),
        },
        components: {
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            overlay: () => import(/* webpackChunkName: "ContactUs" */ '../views/ContactUs')
        },
        beforeEnter(to, from, next) {
            to.params.pause = true
            next()
        }
    },
    {
        path: '/about',
        props: {
            overlay: true,
            default: true,
            player: true,
            menu: true,
            // navbar: {
            //     hasClose: true
            // }
        },
        components: {
            logo: () => import(/* webpackChunkName: "Logo"*/ '../views/Logo'),
            navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar'),
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            back: () => import(/* webpackChunkName: "Overlay" */ '../views/PageOverlay'),
            post: () => import(/* webpackChunkName: "Single" */ '../views/PageSingle'),
            menu: () => import(/* webpackChunkName: "NavbarOverlay" */ '../views/NavbarOverview')
        },
        children: [
            {
                path: ':page',
                name: c.POST,
                props: true,
                components: {
                    default: () => import(/* webpackChunkName: "SingleInner" */ '../views/PageSingleInner'),
                    nav: () => import(/* webpackChunkName: "SingleInner" */ '../views/PageSingleNav')
                },

                beforeEnter: pauseVideo
            }
        ],
        beforeEnter: beforePostEnter
    },


    // {
    //     path: '/about/:page',
    //     name: c.POST,
    //     props: {
    //         overlay: true,
    //         default: true,
    //         player: true,
    //         menu: true,
    //         // navbar: {
    //         //     hasClose: true
    //         // }
    //     },
    //     components: {
    //         logo: () => import(/* webpackChunkName: "Logo"*/ '../views/Logo'),
    //         navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar'),
    //         player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
    //         post: () => import(/* webpackChunkName: "Single" */ '../views/PageSingle'),
    //     },
    //     beforeEnter: beforePostEnter
    // },
    {
        path: '/:page',
        name: c.PAGE,
        props: {
            overlay: true,
            default: true,
            player: true,
            menu: true,
            // navbar: {
            //     hasClose: true
            // }
        },
        components: {
            logo: () => import(/* webpackChunkName: "Logo"*/ '../views/Logo'),
            navbar: () => import(/* webpackChunkName: "Navbar"*/ '../views/Navbar'),
            player: () => import(/* webpackChunkName: "Watch" */ '../views/Watch'),
            back: () => import(/* webpackChunkName: "Overlay" */ '../views/PageOverlay'),
            page: () => import(/* webpackChunkName: "Overlay" */ '../views/PagePromo'),
        },
        beforeEnter: beforePageEnter
    },

    {
        path: '/404',
        alias: '*',
        name: c.NOT_FOUND,
        components: {
            default: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFoundPage'),
            player: () => import(/* webpackChunkName: "NotFound" */ '../views/NotFoundWatch'),
            logo: () => import(/* webpackChunkName: "Logo"*/ '../views/Logo'),
        },
        props: {
            default: true,
            player: true,
        },
        beforeEnter: (to, from, next) => {
            console.log('not found')
            to.params.id = 404
            to.params.pause = false
            next()
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach(rememberVideo)

export default router;
